
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import NavigationLayout from "components/layout/NavigationLayout";
import { NewTopTabBar } from "components/TopTabBar";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef, useState } from "react";
import { Axios, defaultCacheOption } from "api";
import { productScrapContextRef } from "context/ProductScrapContext";
import utilStyles from "styles/utils.module.scss";
import TitleBar from "components/layout/TitleBar";
import styles from "pages/store/special-offers.module.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";
import Loading from "components/Loading";
import { Col, Row } from "react-bootstrap";
import ProductListCard from "components/cards/ProductListCard";
import { Error } from "pages/palette/creator/[ccode]";
import CategoryContext from "context/CategoryContext";
import StoreNavBar from "components/layout/NavBar/StoreNavBar";
import { REWARD_KEY_SPECIAL_OFFER_CHECK } from "pages/rewards";
import { captureException, captureMessage } from "@sentry/nextjs";
import { showRewardToast } from "common/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import useTranslation from "next-translate/useTranslation";
const KEY_SELL_COUNT = 'sell_count';
export const KEY_PROMOTION_ENDS_AT = 'promotion_ends_at';
export const KEY_RANK = 'rank';
const KEY_RECENT = 'recent';
const KEY_LOW_PRICE = 'low_price';
const KEY_HIGH_PRICE = 'high_price';
const CATEGORY_ALL = 'ALL';
const CATEGORY_HAIR = 'HAIR';
const CATEGORY_MAKEUP = 'MAKEUP';
const CATEGORY_SKINCARE = 'SKINCARE';
const defaultData = {
    products: [],
    error: null
};
const PAGE_SIZE = 20;
const SpecialOffers = () => {
    const router = useRouter();
    const { t } = useTranslation('store-special-offers');
    const cursorRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const { getCategoryIdByName, productCategories, getCategoryById } = useContext(CategoryContext);
    const category = router.query.category;
    const categorySplitList = !!category ? category.split('_') : [];
    const categoryIdOrName = categorySplitList[categorySplitList.length - 1];
    const categoryInfo = getCategoryById(category);
    const categoryName = categoryInfo ? categoryInfo.name : null;
    const topTabBarRef = useRef();
    const [data, setData] = useState(defaultData);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const order = router.query.order;
    const completeReward = async () => {
        try {
            const res = await Axios.post(`/v1/rewards/`, {
                reward_key: REWARD_KEY_SPECIAL_OFFER_CHECK
            });
            if (res.status < 400) {
                showRewardToast(router, res.data.given_reward.key, res.data.given_reward.point);
            }
            else {
                captureMessage(JSON.stringify(res.data));
            }
        }
        catch (e) {
            captureException(e);
        }
    };
    useEffect(() => {
        setTimeout(() => {
            completeReward();
        }, 2000);
    }, []);
    useEffect(() => {
        if (isNaN(router.query?.order)) {
            router.replace({
                query: Object.assign({}, router.query, {
                    order: order ?? KEY_RANK
                })
            }, undefined, { shallow: true, scroll: true });
        }
    }, []);
    useEffect(() => {
        if (!router.isReady || productCategories.length === 0) {
            return;
        }
        if (!(router.query?.order)) {
            return;
        }
        cursorRef.current = null;
        setData(JSON.parse(JSON.stringify(defaultData)));
        setIsLoading(true);
        fetchProducts();
        setIsLoading(false);
    }, [router.isReady, router.query?.category, router.query?.order, productCategories]);
    const fetchProducts = async () => {
        try {
            const params = {
                category_id: categoryIdOrName,
                order: order,
                cursor: cursorRef.current,
                size: PAGE_SIZE
            };
            const loggingData = Object.assign({}, params);
            const res = await Axios.get('v1/store/special-offer-products/', {
                params: params,
                cache: defaultCacheOption
            });
            if (res.status < 400) {
                cursorRef.current = res.data.cursor;
                setData(prev => {
                    const newProducts = res.data.products;
                    return {
                        ...prev,
                        products: [...prev.products, ...newProducts],
                        error: null
                    };
                });
                productScrapContextRef.current.updateByProductIdsAndScrappedProductIds(res.data.products.map(e => e.id), res.data['scrapped_pids']);
                loggingData['success'] = true;
            }
            else {
                setData(values => {
                    const newValues = JSON.parse(JSON.stringify(values));
                    newValues.error = res.data.display_message || t('specialOffers.fetchError');
                    return newValues;
                });
                loggingData['success'] = false;
            }
            typeof mixpanel !== 'undefined' && mixpanel.track('ViewSpecialOffer', loggingData);
        }
        catch (e) {
            setData(values => {
                const newValues = JSON.parse(JSON.stringify(values));
                newValues.error = t('specialOffers.fetchError');
                return newValues;
            });
        }
        finally {
        }
    };
    const onProductClick = (product, itemIdx) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('SpecialOfferProductClick', {
            productId: product.id,
            productName: product.name,
            itemIdx
        });
    };
    const logListOrderChange = (order) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('SpecialOffersStoreListOrderChange', {
            order: order,
            category: categoryName
        });
    };
    return (<div className={classNames(styles.container, utilStyles.pageContainer)}>
            <div className={classNames(utilStyles.sidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                <TitleBar title={t('specialOffers.title')} close={() => router.back()} isBack/>
                <div style={{ height: 4 }}/>
            </div>
            <StoreNavBar changeQueryParamOnly hideSubCategory noSubCategoryMarginBottom maintainScroll replace={true}/>
            {<>
                    <div className={classNames(styles.topTabBarWrapper, !isMobile ? utilStyles.sidePadding : undefined)}>
                        <NewTopTabBar smallText activeRoute={order} tabs={[
                {
                    route: KEY_RANK, title: t('specialOffers.recommendedOrder'), onClick: () => {
                        logListOrderChange(KEY_RANK);
                        router.replace({
                            query: Object.assign({}, router.query, {
                                order: KEY_RANK
                            })
                        }, undefined, { shallow: true, scroll: true });
                    }
                },
                {
                    route: KEY_SELL_COUNT, title: t('specialOffers.salesOrder'), onClick: () => {
                        logListOrderChange(KEY_SELL_COUNT);
                        router.replace({
                            query: Object.assign({}, router.query, {
                                order: KEY_SELL_COUNT
                            })
                        }, undefined, { shallow: true, scroll: true });
                    }
                },
                {
                    route: KEY_RECENT, title: t('specialOffers.latestOrder'), onClick: () => {
                        logListOrderChange(KEY_RECENT);
                        router.replace({
                            query: Object.assign({}, router.query, {
                                order: KEY_RECENT
                            })
                        }, undefined, { shallow: true, scroll: true });
                    }
                },
                {
                    route: KEY_LOW_PRICE, title: t('specialOffers.lowPriceOrder'), onClick: () => {
                        logListOrderChange(KEY_LOW_PRICE);
                        router.replace({
                            query: Object.assign({}, router.query, {
                                order: KEY_LOW_PRICE
                            })
                        }, undefined, { shallow: true, scroll: true });
                    }
                },
                {
                    route: KEY_HIGH_PRICE, title: t('specialOffers.highPriceOrder'), onClick: () => {
                        logListOrderChange(KEY_HIGH_PRICE);
                        router.replace({
                            query: Object.assign({}, router.query, {
                                order: KEY_HIGH_PRICE
                            })
                        }, undefined, { shallow: true, scroll: true });
                    }
                },
            ]}/>
                    </div>
                    {isLoading ? <Loading style={{ marginTop: 60 }}/>
                :
                    <InfiniteScroll scrollThreshold={'80%'} hasMore={!!cursorRef.current} dataLength={data.products.length} next={fetchProducts} loader={<div>
                                    <Loading style={{ marginTop: 20, marginBottom: 20 }}/>
                                </div>} style={{ overflow: 'hidden' }} endMessage={undefined}>
                            <div className={styles.contentSection}>
                                {<div className={styles.productsWrapper}>
                                            <Row>
                                                {data.products.map((e, idx) => {
                                return (<Col key={e.id} xs={isLarge ? 12 : 6} className={styles.col}>
                                                                <div className={styles.cardWrapper}>
                                                                    <ProductListCard product={e} showPromotionDaysLeft onClick={() => onProductClick(e, idx)} textPaddingType="medium"/>
                                                                </div>
                                                            </Col>);
                            })}
                                            </Row>
                                        </div>}
                            </div>
                        </InfiniteScroll>}
                    {data.error && <Error error={data.error} message={data.error || t('specialOffers.noSpecialOffers')}/>}
                </>}
        </div>);
};
SpecialOffers.getLayout = NavigationLayout;
export default SpecialOffers;
SpecialOffers.restoreScroll = true;

    async function __Next_Translate__getStaticProps__194f8eb1b0f__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/store/special-offers',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194f8eb1b0f__ as getStaticProps }
  